// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {
    th, td {
        white-space: nowrap;
    }
}

// Motion Design additions

table > thead > tr {
    background-color: $secondary;
    color: white;
}

tr.success {
    background-color: $success-lighten !important;
}

tr.danger {
    background-color: $danger-lighten !important;
}

tr.warning {
    background-color: $warning-lighten !important;
}

.thead-light.danger {
    th {
        background-color: $danger-lighten;
    }
}