/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

$worklog-cell-locked-bg-colour: #F6F6F6;
$worklog-cell-modified-bg-colour: #2C8EF833;
$worklog-cell-server-bg-colour: #2CF88E33;
$worklog-cell-over-bg-colour: #E7284A33;

.timesheets-controls {

    > div:not(:last-child) {
        flex-basis: content;
        flex-grow: 0;
    }

    > div:last-child {
        > * {
            white-space: nowrap;
        }
    }
}

.timesheets-table {

    .worklog-cell {
        width: 8%;
        min-width: 100px;
        font-weight: normal;

        &.locked {
            background-color: $worklog-cell-locked-bg-colour;
            font-weight: lighter;
            opacity: 0.8;
        }

        &.uneditable {
            font-weight: lighter;
            opacity: 0.5;
        }

        &.modified { background-color: $worklog-cell-modified-bg-colour; }
        &.server { background-color: $worklog-cell-server-bg-colour; }
        &.over { background-color: $worklog-cell-over-bg-colour; }
    }

    .timesheet-details-entry {
        overflow: visible;
        padding: 0 2rem;
        align-items: center;

        &.modified .timesheet-details-entry_duration span { background-color: $worklog-cell-modified-bg-colour; }
        &.server .timesheet-details-entry_duration span { background-color: $worklog-cell-server-bg-colour; }

        &:not(:last-child) {
            @extend .table-bordered;
            border-top-width: 0;
            border-left-width: 0;
            border-right-width: 0;
        }

        > .col { @extend .p-2; }

        .worklog-entry-editor {
            input {
                @extend .px-2;
                @extend .py-0;
                height: 1.5rem;
            }
        }

        &.login .timesheet-details-entry_name {
            background-color: white;
            z-index: 2;
        }

        &:not(.login) .timesheet-details-entry_name {
            z-index: 1;

            > strong { @extend .pl-3; }

            &::before {
                content: '';
                display: block;
                position: absolute;
                background-color: $info;
                width: 2px;
                height: calc(100% + 1px);
                bottom: 50%;
                left: 1rem;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                background-color: $info;
                border-radius: 2px;
                width: 4px;
                height: 4px;
                bottom: 50%;
                left: calc(1rem - 1px);
            }
        }
    }
}
