/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2020] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */


.alert-light {
  border-color: #e8e8e8;
}

.border-2 {
  border-width: 2px !important;
}

.modal,
.text-md-blacksand {
  color: $md-blacksand !important;
}

.react-datepicker-popper {
  width: 320px;
}

.text-orange {
  color: $orange !important;
}

.text-purple {
  color: #7131DB !important;
}

.custom-control-label::after {
  border: 1px solid $md-blacksand;
  border-radius: 3px;
}

hr.separator {
  border-top: 1px solid $md-blacksand;
}

.bg-gray-500 {
  background: $gray-500;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled {
  cursor: default;
  color: #e0e4e6 !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #3b4246;
  display: inline-block;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
  background-color: #001649;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  display: inline-block;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover,
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
  background-color: #001649;
  color: white;
}

.page-title-box {
  .page-title {
    text-transform: uppercase;
  }
}

.side-nav .side-nav-link {
  text-transform: uppercase;
}

.auth-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.table {
  .thead-white {
    background-color: white;
    color: $dark;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,0.10) !important;

    th {
      border: none;
    }
  }
}

.btn {
  box-shadow: none;
}

.btn-link-hover {
  color: rgba(0,0,0,0.3) !important;

  &:hover {
    color: rgba(0,0,0,0.5) !important;

      &.text-danger {
          color: $danger !important;
      }

      &.text-success {
          color: $success !important;
      }
  }
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.flex-fit {
  flex-grow: 0;
  flex-basis: fit-content;
}

.flex-xl-grow {
  @include media-breakpoint-up(xl) {
    flex-grow: 1 !important;
  }
}

/*
Greenmount
*/

.content-page-terminal-landscape .footer,
.content-page-terminal-portrait .footer {
  left:0;
}

.qr-scanner {
  background-color: $black;
  
  div {
    box-shadow: rgba($white, 0.5) 0px 0px 0px 5px inset !important;
  }
}
