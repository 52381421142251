/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2022] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

 $terminal-select-max-width: 300px;

// Overrides
.content-page-terminal-landscape,
.content-page-terminal-portrait {
  margin-left: 0 !important;
  padding-top: $topbar-height;
}

.content-page-terminal-landscape {
  padding-right: $sidebar-vertical-terminal-width
}

.content-page-terminal-portrait {
  padding-bottom: $sidebar-horizontal-terminal-height;
}

.footer-terminal {
  left: 0 !important;
}

.navbar-terminal {
  left: 0 !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05)
}

// Sidebar

.sidebar-terminal-horizontal,
.sidebar-terminal-vertical {
  position: absolute;
  display: flex;
  padding: $sidebar-terminal-padding;
  bottom: $footer-terminal-height;
}

.sidebar-terminal-horizontal {
  flex-direction: row;
  white-space: nowrap;
  border-top: 1px solid rgba($gray-600, 0.2);
}

.sidebar-terminal-vertical {
  right: 0;
  top: $topbar-height;
  flex-direction: column;
  border-left: 1px solid rgba($gray-600, 0.2);
}

.sidebar-terminal-button {
  position: relative;
  width: $sidebar-vertical-terminal-width - $sidebar-terminal-padding * 2;
  height: $sidebar-horizontal-terminal-height - $sidebar-terminal-padding * 2;
}

.sidebar-terminal-modal-content {
  position: relative;
  width: $sidebar-vertical-terminal-width - $sidebar-terminal-padding * 2;
  vertical-align: center;
}

.sidebar-terminal-horizontal button {
  margin-right: calc($sidebar-terminal-padding / 2);
  flex: 1;
}

.sidebar-terminal-vertical button {
  margin-bottom: calc($sidebar-terminal-padding / 2);
}

.sidebar-terminal-inner {
  overflow: auto;
  flex: 1;
}

.terminal-modal-topbar-btn {
  color: white; 
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  background-color: rgba($gray-600, 0.2);
  box-shadow: none !important;

  &-left {
    @extend .terminal-modal-topbar-btn;
    border-radius: 3px 0 0 0;
  }

  &-right {
    @extend .terminal-modal-topbar-btn;
    border-radius: 0 0 0 3px;
  }

  &:focus {
    background-color: rgba($gray-600, 0.2) !important;
  }

  &:hover, &:active {
    background-color: rgba($gray-600, 0.4) !important;
  }
}

.tasks-list {
  flex-wrap: nowrap;
  overflow-y: auto;
  position: relative;;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}

.task-details-part-filter {
  max-width: $terminal-select-max-width;
}

.task-details-parts-list {
  flex-wrap: nowrap;
  overflow-y: auto;

  > .row {
    scroll-margin: 12vh 0;
    border-bottom: 1px solid #eef2f7 !important;
    transition: background-color 0.25s ease-in-out;
  }
}

.task-component {
  width: 100%;
  height: $task-component-height;
  min-height: $task-component-height;
  border-radius: 0 5px 5px 0;
}

.task-component-heading:not(:last-child):not(.collapsed) + .collapse {
  margin-bottom: 2rem !important;
}

.task-component-left {
  width: calc($task-component-height / 2);
  height: 100%;
  border-radius: 5px 0 0 5px;
  border-right: $gray-200 1px solid;
}

.task-component-right {
  border-left: $gray-200 1px solid;

  .task-count {
    min-width: calc($task-component-height * 1.5);
    text-align: center;
  }
}

.terminal-layout-content-landscape {
  height: calc(100vh - #{$topbar-height} - #{$footer-terminal-height});
}

.terminal-layout-content-portrait {
  height: calc(100vh - #{$topbar-height} - #{$footer-terminal-height} - #{$sidebar-horizontal-terminal-height});
}

.list-item-task-component-complete {
  background-color: #0acf97;
}

.terminal-qr-code-button {
  width: 150px;
}

.terminal-qr-input-field {
  height: 0;
  padding: 0;
  border: 0;
  margin: 0;
}
