/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.dashboard button {
    width: 90%;
    height: 200px;
    font-size: xx-large;
    line-height: 70px;
    box-shadow: 0 2px 6px 0 rgba(111, 132, 144, 0.5);
    border-radius: 20px;
}

.dashboard button:hover, .dashboard button:focus , .dashboard button:active {
    background: $secondary !important;
    border-color: $secondary !important;
}

.dashboard button i {
    font-size: 60px;
}