/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2021] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

.jobs {
    .job-controls {
        margin: 0 0 0.75rem 0;

        > * {
            flex: 1;

            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }

    .view-details {
        text-align: right;
    }

    .table .thead-light th:not(.view-details) {
        cursor: pointer;
        transition: background-color 0.25s ease-in-out;

        &:hover {
            background-color: #E8EBF0;
        }
    }
}

.job-details {
    .card-body > :not(.row) {
        overflow: clip !important;
    }

    .part-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.75rem;
        font-size: medium;
    }

    .part-controls {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 0.5rem;

        > *:not(:first-child) {
            margin-left: 1rem;
        }
    }

    .part-filter {
        min-width: 200px;
    }

    .part-search-input {
        flex: 1;
        min-width: 0;
        border-radius: 0.25rem 0 0 0.25rem;
    }

    .part-search-button {
        transition: background-color 0.25s ease-in-out;
        border-radius: 0 0.25rem 0.25rem 0;
        box-shadow: none;
    }

    .table {
        table-layout: fixed;
        overflow-x: clip;
    
        thead > tr {
            background: none;
            color: inherit;

            th {
                top: 0;
                z-index: 1;
                position: sticky;
                border-top: none;
                padding: 0.75rem 0;
                background-color: white;
                box-shadow: 0px 2px 2px -1px rgba($color: $black, $alpha: 0.05);
            }
        }

        td, th {
            width: 40%;
            text-align: left;
        }

        td + td, th + th {
            width: auto;
            text-align: center;
        }

        tr {
            th:last-child, td:last-child {
                width: 0;
                padding: 0;
                vertical-align: middle;
                text-align: left;

                i {
                    font-size: 20px;
                    position: absolute;
                    right: 0;
                    bottom: 8px;
                    padding-right: 0.95rem;
                }
            }
        }
    }

    .step-item {
        color: $text-muted;
        &:nth-child(2) {color: $primary;}
        &.done + .step-item {color: $primary;}
        &.done {color: $success !important;}
        &.step-item.check {color: $success !important;}
        &.done + .step-item.check {color: $success !important;}
        &.skipped {color: $danger !important;}

        &:not(.done) ~ .step-item.check {color: $text-muted !important;}

        span {
            font-size: x-small;
            position: relative;
            background-color: white;
            border: 2px solid white;
        }

        span::after, i::after {
            content: '';
            display: block;
            position: absolute;
            left: calc(50% - 5px);
            top: -15px;
            width: 10px;
            height: 10px;
            border: 2px solid white;
            border-radius: 50%;
            background-color: currentColor;
            z-index: 2;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 6px;
            width: 100%;
            height: 2px;
            background-color: currentColor;
            z-index: 2;
        }

        i {
            color: inherit !important;
            background-color: white;

            &::after {
                top: -6px;
                left: 5px;
            }
        }
    }

    .step-item ~ .step-item {
        &::before {
            left: 0;
        }
    }

    .step-item.check::before {
        left: -1.25rem;
        width: 1.25rem;
    }

    .cabinet-row {
        background-color: $gray-200;
        cursor: pointer;

        &:hover {
            background-color: #E8EBF0;
        }

        :nth-child(1) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .cabinet-phase:nth-child(2) {
            color: $primary;
        }

        .cabinet-phase ~ .cabinet-phase {
            color: inherit;
        }

        .cabinet-title {
            font-weight: bold;
        }

        .cabinet-phase {
            font-weight: bold;
        }

        .cabinet-phase.done + .cabinet-phase:not(.dark) {color: $primary;}
        .cabinet-phase.done {color: $success !important;}
        .cabinet-phase.skipped {color: $danger !important;}
    }

    .part-row {
        scroll-margin: 30vh 0;

        &:hover {
            background-color: #F8F9FD;
        }

        &.highlight {
            background-color: rgba($color: $info, $alpha: 0.2);
            animation: blinker 1s forwards;

            @keyframes blinker {
                25% {opacity: 0.2;}
                50% {opacity: 1;}
                75% {opacity: 0.2;}
                100% {opacity: 1;}
            }

            &.part-row-reworked {
                animation: none;
            }
        }

        :nth-child(1) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:not(.part-row-reworked) {
            .phase:nth-child(2) {color: $primary;}

            .phase.done + .phase:not(.dark) {color: $primary;}
        }

        .phase ~ .phase {
            border-left: none !important;
            color: $text-muted;
        }

        .phase.dark {
            background-color: rgba($color: $black, $alpha: 0.1);
        }

        .phase.done {
            border-left: 2px solid rgba($color: $success, $alpha: 0.5);
            background-color: rgba($color: $success, $alpha: 0.2);
        }

        .phase.skipped {
            border-left: 2px solid rgba($color: $danger, $alpha: 0.5);
            background-color: rgba($color: $danger, $alpha: 0.2);
        }

        .phase.done {color: $success !important;}
        .phase.skipped {color: $danger !important;}
    }
}

.part-details {
    .table {
        table-layout: fixed;
    }

    .table td {
        word-wrap: break-word;
    }

    th:not(:last-child) {
        width: 40%;
    }
}
